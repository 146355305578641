import { useEffect } from 'react';
import type { AppProps } from 'next/app';

import { DefaultSeo } from 'next-seo';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Login from '@components/core/Login';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import theme from '@styles/theme';
import AppContext from '@hooks/app';
import config from 'config/seo.json';
import EditProfile from '@components/core/EditProfile';
import { Header, LottieLoading } from '@components/core';
import { useCourse } from '@hooks/course';

import '../assets/css/hubspot-chat.css';
import Menu from '@components/core/Menu';
import { appWithTranslation } from 'next-i18next';

const AppBeforeLoading: React.FC<AppProps> = ({ Component, pageProps }) => {
  const { loading } = useCourse();

  if (loading) return <LottieLoading loading />;

  return (
    <Menu>
      <Component {...pageProps} />
    </Menu>
  );
};

const MyApp: React.FC<AppProps> = props => {
  useEffect(() => {
    dayjs.locale('pt-br');

    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <>
      <DefaultSeo {...config} />
      <AppContext>
        <ThemeProvider theme={theme}>
          <Header>
            <CssBaseline />
            <AppBeforeLoading {...props} />
            <Login />
            <EditProfile />
          </Header>
        </ThemeProvider>
      </AppContext>
    </>
  );
};

export default appWithTranslation(MyApp);
