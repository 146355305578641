import axios from 'axios';
import cookies from '@utils/cookies';
import { AUTH_USER_TOKEN } from '@utils/cookies/authCookie';

const api = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API}api/`,
});

api.interceptors.request.use(oldConfig => {
  let config = oldConfig;
  const authUserToken = cookies.get(AUTH_USER_TOKEN);
  const language = cookies.get('startseLocale');
  if (authUserToken) {
    Object.assign(config.headers, {
      'auth-user-token': authUserToken,
    });
  }
  if (config.params) {
    config.params = {
      ...config.params,
      language,
    };
  } else {
    config = {
      ...config,
      params: {
        language,
      },
    };
  }
  return config;
});

export default api;
