import { createContext, useContext, useEffect, useState, useMemo } from 'react';

import * as AuthService from '@services/auth';
import cookies from '@utils/cookies';
import AUTH_TOKEN_COOKIE, { AUTH_USER_TOKEN } from '@utils/cookies/authCookie';
import { useUser } from '@hooks/user';
import { useLoading } from '@hooks/loading';
import { useRouter } from 'next/router';
import { useTracking } from './tracking';

interface IAuthContextData {
  showModalLogin: boolean;
  loginURL: string;
  setShowModalLogin(value: boolean): void;
  logout(redirect?: string): void;
  showModalCompleted: boolean;
  setShowModalCompleted: (value: boolean) => void;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const { setUser } = useUser();
  const { addLoading, removeLoading } = useLoading();
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [showModalCompleted, setShowModalCompleted] = useState(false);
  const { push } = useRouter();
  const { identify } = useTracking();
  const router = useRouter();
  const [currentUrl, setCurrentUrl] = useState('');

  const loginURL = useMemo(
    () =>
      `${process.env.NEXT_PUBLIC_AUTH_CLIENT}authentication/signinV2?lng=${router.locale}&redirect_uri=${currentUrl}&client_view=platform`,
    [currentUrl, router.locale],
  );

  const logout = (): void => {
    cookies.remove(AUTH_TOKEN_COOKIE);
    cookies.remove(AUTH_USER_TOKEN);

    window.location.href = `${process.env.NEXT_PUBLIC_AUTH_API}api/auth/signout?redirect_to=${currentUrl}`;
  };

  useEffect(() => {
    setCurrentUrl(window.location.href);
    const validateLogged = async (): Promise<void> => {
      addLoading();

      try {
        const { user, token } = await AuthService.getUser();
        cookies.set(AUTH_USER_TOKEN, token);
        if (!user || !user._id) {
          push('/');
          throw new Error();
        }
        setUser(user);
        identify(user.email);
        if (!user.jobtitle || !user.phone) {
          setShowModalCompleted(true);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        removeLoading();
      }
    };

    if (
      !process.env.NEXT_PUBLIC_AUTH_API ||
      !process.env.NEXT_PUBLIC_AUTH_CLIENT
    )
      return;

    if (!cookies.get(AUTH_TOKEN_COOKIE)) {
      push('/');
      setShowModalLogin(true);
      return;
    }

    validateLogged();
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{
        logout,
        loginURL,
        showModalLogin,
        setShowModalLogin,
        showModalCompleted,
        setShowModalCompleted,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('Auth must be used within a AuthProvider');
  }

  return context;
}

export { useAuth, AuthProvider };
