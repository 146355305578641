import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IUser } from '@utils/interfaces/user';
import { IStudentEnrollment } from '@utils/interfaces/studentEnrollment';
import { StudentEnrollmentService } from '@services/content';

interface IUserContextData {
  user: IUser;
  showEditProfile: boolean;
  setShowEditProfile(showEditProfile: boolean): void;
  setUser(user: IUser): void;
  setStudentEnrollments(studentEnrollment: IStudentEnrollment[]): void;
  studentEnrollments: IStudentEnrollment[];
}

export const UserContext = createContext<IUserContextData>(
  {} as IUserContextData,
);

const UserProvider: React.FC = ({ children }) => {
  const [studentEnrollments, setStudentEnrollments] = useState<
    IStudentEnrollment[]
  >([]);
  const [user, setUser] = useState<IUser>({} as IUser);
  const [showEditProfile, setShowEditProfile] = useState(false);

  const getUserEnrollments = useCallback(async () => {
    const enrollments = await StudentEnrollmentService.findAll();
    setStudentEnrollments(enrollments.rows);
  }, []);

  useEffect(() => {
    if (user._id) {
      getUserEnrollments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        setStudentEnrollments,
        studentEnrollments,
        showEditProfile,
        setShowEditProfile,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

function useUser(): IUserContextData {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('User must be used within a UserProvider');
  }

  return context;
}

export { useUser, UserProvider };
