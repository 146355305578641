import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://fe7a4d04837444b0b843970ec2390e14@o294874.ingest.sentry.io/5906001',
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
});
