import { IUser } from '@utils/interfaces/user';
import api from './api';

export const getUser = async (): Promise<{ user: IUser; token: string }> => {
  const userResponse = await api.get<IUser>('oauth2/profile?streamToken=true', {
    headers: {
      'auth-user-token': true,
    },
  });
  return {
    user: userResponse.data,
    token: userResponse.headers['auth-user-token'],
  };
};
