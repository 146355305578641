import { IResponse } from '@utils/interfaces/response';
import { IStudentCourseLesson } from '@utils/interfaces/studentCourseLesson';

import api from '../api';

export const findAll = async (
  courseId?: string,
  orderBy?: string,
  limit?: string,
): Promise<IResponse<IStudentCourseLesson>> => {
  const studentCourseLesson = await api.get(
    '/lms-student/student-course-lesson',
    {
      params: {
        'filter[course]': courseId,
        orderBy,
        limit,
      },
    },
  );
  return studentCourseLesson.data;
};

export const findById = async (
  id: string | number,
): Promise<IStudentCourseLesson> => {
  const course = await api.get(`student-course-lesson/${id}`);
  return course.data;
};

export const update = async (
  studentCourseLessonid: string,
  assistedTime: number,
  hasCompleted: boolean,
  courseLesson: string,
  student: string,
): Promise<IStudentCourseLesson> => {
  const enrollment = await api.put(
    `/lms-student/student-course-lesson/${studentCourseLessonid}`,
    {
      data: {
        assistedTime,
        hasCompleted,
        courseLesson,
        student,
      },
      id: studentCourseLessonid,
    },
  );
  return enrollment.data;
};

export const create = async (
  assistedTime: number,
  hasCompleted: boolean,
  courseLesson: string,
  student: string,
): Promise<IStudentCourseLesson> => {
  const enrollment = await api.post(`/lms-student/student-course-lesson`, {
    data: {
      hasCompleted,
      assistedTime,
      courseLesson,
      student,
    },
  });
  return enrollment.data;
};
