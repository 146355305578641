import { useEffect, useState } from 'react';

import { useAuth } from '@hooks/auth';
import { useUser } from '@hooks/user';

import MuiBackdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@components/core/Backdrop';
import CloseIcon from '@material-ui/icons/CloseRounded';
import { Dialog, Iframe, DialogContent, CloseButton } from './styles';

const Login: React.FC = () => {
  const { showModalLogin, setShowModalLogin, loginURL } = useAuth();
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingIframe, setIsLoadingIframe] = useState(false);

  useEffect(() => {
    const handleMessages = message => {
      if (
        !(loginURL || '').includes(message.origin) ||
        (typeof message.data !== 'string' && !(message.data instanceof String))
      )
        return;

      const data = JSON.parse(message.data);

      if (data.action !== 'login') return;

      window.location.href = data.redirect;
      setShowModalLogin(false);
      setIsLoading(true);
    };

    window.addEventListener('message', handleMessages);

    return () => {
      window.removeEventListener('message', handleMessages);
    };
  }, [loginURL, setShowModalLogin, showModalLogin]);

  useEffect(() => {
    if (!showModalLogin) return;

    setIsLoadingIframe(true);
  }, [showModalLogin]);

  const onClose = () => {
    setShowModalLogin(false);
  };

  if (user && user._id) return null;

  if (isLoading)
    return (
      <MuiBackdrop open style={{ zIndex: 90 }}>
        <CircularProgress />
      </MuiBackdrop>
    );

  return (
    <Dialog
      open={showModalLogin}
      aria-labelledby="login"
      onClose={onClose}
      BackdropComponent={Backdrop}
    >
      <DialogContent dividers style={{ WebkitOverflowScrolling: 'touch' }}>
        {isLoadingIframe && (
          <CircularProgress style={{ position: 'absolute' }} />
        )}
        <Iframe
          src={loginURL}
          title="Startse login modal"
          frameBorder="0"
          scrolling="yes"
          onLoad={() => setIsLoadingIframe(false)}
          style={{ opacity: isLoadingIframe ? '0' : '1' }}
        />
        <CloseButton onClick={onClose}>
          <CloseIcon fontSize="default" />
        </CloseButton>
      </DialogContent>
    </Dialog>
  );
};

export default Login;
