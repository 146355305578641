import React, { createContext, useContext, useState, useCallback } from 'react';
import cookies from '@utils/cookies';
import AUTH_TOKEN_COOKIE from '@utils/cookies/authCookie';

interface TrackingContextData {
  track(eventName: string, props: object): void;
  identify(email: string): void;
}

const TrackingContext = createContext<TrackingContextData>(
  {} as TrackingContextData,
);

const TrackingProvider: React.FC = ({ children }) => {
  const track = useCallback((eventName, props = {}) => {
    const win = window as any;
    if (win._kmq) {
      if (cookies.get(AUTH_TOKEN_COOKIE)) {
        props.isLoggedIn = true;
      } else {
        props.isLoggedIn = false;
      }
      try {
        win._kmq.push(['record', eventName, props]);
      } catch {}
    } else {
      console.log('Não enviou kissmetrics!');
    }
  }, []);

  const identify = useCallback(email => {
    const win = window as any;
    if (win._kmq) {
      try {
        win._kmq.push(['identify', email]);
        return true;
      } catch {}
    }
  }, []);

  return (
    <TrackingContext.Provider value={{ track, identify }}>
      {children}
    </TrackingContext.Provider>
  );
};

function useTracking(): TrackingContextData {
  const context = useContext(TrackingContext);

  if (!context) {
    throw new Error('Tracking must be used within a TrackingProvider');
  }

  return context;
}

export { useTracking, TrackingProvider };
