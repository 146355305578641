import NextHead from 'next/head';

const Head: React.FC = ({ children }) => {
  return (
    <NextHead>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {children}
    </NextHead>
  );
};

export default Head;
