import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';
import { useUser } from '@hooks/user';
import { useAuth } from '@hooks/auth';
import {
  HeaderPlatform,
  MenuOption,
  RatingModal,
} from '@startse/react-components';
import AlertConfirm from '@components/core/AlertConfirm';
import ShareArea from '@components/core/Share/ShareArea';
import cookies from '@utils/cookies';
import {
  HomeIcon,
  ArticlesIcon,
  VideoIcon,
  PodcastIcon,
  TicketIcon,
  ProgramIcon,
  WebSerieIcon,
  CourseIcon,
} from '../Icons';

const TRACKING = 'utm_source=lms';

const alertMessagesByPath = {
  '/curso/[slug]/jornada/[lesson-slug]': 'Tem certeza que deseja sair da aula?',
  generic: 'Tem certeza que deseja sair da área de cursos?',
};

const Header: React.FC = ({ children }) => {
  const [currentPath, setCurrentPath] = useState(null);
  const { user, setShowEditProfile } = useUser();
  const { setShowModalLogin, logout } = useAuth();
  const [evaluatingSpeaker, setEvaluatingSpeaker] = useState('');
  const userData = {
    id: user?._id,
    photoUrl: user?.profileImageURL,
    name: `${user?.firstName} ${user?.lastName}`,
  };

  const { push, pathname, locale, asPath } = useRouter();

  useEffect(() => {
    cookies.set('startseLocale', locale);
  }, [locale]);

  const handleMenu = useCallback(
    (path: string, isInside) => {
      if (isInside) push(path);
      else {
        setCurrentPath(path);
      }
    },
    [push],
  );

  const handleOutsideLink = useCallback(() => {
    if (!currentPath) return;
    window.location.href = `${process.env.NEXT_PUBLIC_PLATFORM}${currentPath}${
      currentPath.indexOf('?') > -1 ? '&' : '?'
    }${TRACKING}`;
  }, [currentPath]);

  const alertMessage = useMemo(
    () => alertMessagesByPath[pathname] ?? alertMessagesByPath.generic,
    [pathname],
  );

  const menuOptions = [
    { label: 'Inicio', icon: <HomeIcon />, path: '/', isSelected: false },
    {
      label: 'Artigos',
      icon: <ArticlesIcon />,
      path: '/artigos',
      isSelected: false,
    },
    { label: 'Aulas', icon: <VideoIcon />, path: '/aulas', isSelected: false },
    {
      label: 'Cursos',
      icon: <CourseIcon />,
      path: '/',
      isSelected: true,
      insideLink: true,
    },
    {
      label: 'Eventos',
      icon: <TicketIcon />,
      path: '/eventos',
      isSelected: false,
    },
    {
      label: 'Programas',
      icon: <ProgramIcon />,
      path: '/programas',
      showSoon: true,
      isSelected: false,
    },
    {
      label: 'Podcasts',
      icon: <PodcastIcon />,
      path: '/podcasts',
      showSoon: true,
      isSelected: false,
    },
    {
      label: 'Web Séries',
      icon: <WebSerieIcon />,
      path: '/web-series',
      showSoon: true,
      isSelected: false,
    },
  ];

  return (
    <HeaderPlatform
      onPressLogo={() => push('/')}
      logoutOnClick={logout}
      noUserMenuClicked={() => setShowModalLogin(true)}
      openProfileOnClick={() => setShowEditProfile(true)}
      user={user && user._id ? userData : undefined}
      onNotificationClick={(link, action) => {
        if (link) document.location.href = link;
        if (action?.type === 'rateEventSpeaker' && action?.data?.activity)
          setEvaluatingSpeaker(action.data.activity);
      }}
      language={locale}
      handleLanguage={newLanguage => {
        push(asPath, asPath, { locale: newLanguage });
      }}
      menuOptions={menuOptions.map(
        item =>
          ({
            ...item,
            onClick: () => handleMenu(item.path, item.insideLink),
          } as MenuOption),
      )}
      onSearchFullPage={term => handleMenu(`/busca?q=${term}`, false)}
      onSearchItemClick={item => handleMenu(item.path, false)}
    >
      {user?._id && (
        <RatingModal
          closeModalControlled={() => setEvaluatingSpeaker(null)}
          contentId={evaluatingSpeaker}
          showModalControlled={!!evaluatingSpeaker}
          type="eventActivity"
          userId={user._id}
          ShareComponent={<ShareArea />}
        />
      )}
      {children}
      <AlertConfirm
        callback={handleOutsideLink}
        onClose={() => setCurrentPath(null)}
        open={Boolean(currentPath)}
        question={alertMessage}
      />
    </HeaderPlatform>
  );
};

export default Header;
