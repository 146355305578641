import { UserProvider } from './user';
import { AuthProvider } from './auth';
import { LoadingProvider } from './loading';
import { SnackbarProvider } from './snackbar';
import { TrackingProvider } from './tracking';
import { CourseProvider } from './course';

const AppProvider: React.FC = ({ children }) => (
  <TrackingProvider>
    <SnackbarProvider>
      <LoadingProvider>
        <UserProvider>
          <AuthProvider>
            <CourseProvider>{children}</CourseProvider>
          </AuthProvider>
        </UserProvider>
      </LoadingProvider>
    </SnackbarProvider>
  </TrackingProvider>
);

export default AppProvider;
