import React from 'react';
import { Label, Container, BorderLinearProgress } from './styles';

interface IProgress {
  value: number;
}
const LinearProgress: React.FC<IProgress> = ({ value }) => {
  return (
    <Container>
      <Label>{`${Math.round(value)}%`}</Label>
      <BorderLinearProgress
        variant="determinate"
        value={value}
        color="primary"
      />
    </Container>
  );
};

export default LinearProgress;
