import { IResponse } from '@utils/interfaces/response';
import { IStudentEnrollment } from '@utils/interfaces/studentEnrollment';
import api from '../api';

export const findAll = async (): Promise<IResponse<IStudentEnrollment>> => {
  const params = new URLSearchParams();
  const enrollment = await api.get('/lms-student/student-enrollment', {
    params,
  });
  return enrollment.data;
};

export const find = async (id: string): Promise<IStudentEnrollment> => {
  const enrollment = await api.get(`student-enrollment/${id}`);
  return enrollment.data;
};
