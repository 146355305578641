import { useCourse } from '@hooks/course';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

import {
  Container,
  ContainerMenu,
  Label,
  Title,
  Text,
  ContainerList,
  BorderBottom,
} from './styles';

const Menu: React.FC = ({ children }) => {
  const { course } = useCourse();
  const router = useRouter();
  const { t } = useTranslation('common');

  const menuItems = useMemo(
    () => [
      {
        text: t('menu_home'),
        path: '/',
      },
      {
        text: t('menu_journey'),
        path: '/jornada',
      },
      {
        text: t('menu_networking'),
        path: '/networking',
      },
      {
        text: t('menu_schedule'),
        path: '/agenda',
      },
      {
        text: t('menu_materials'),
        path: '/materiais',
      },
      {
        text: t('menu_certificate'),
        path: '/certificado',
      },
      {
        text: t('menu_help'),
        path: '/ajuda',
      },
    ],
    [t],
  );

  const hiddenMenu = useMemo(() => {
    const pathNameSplit = router?.pathname.split('/');
    return (
      (pathNameSplit[3] === 'jornada' && pathNameSplit[4]) ||
      router?.pathname === '/'
    );
  }, [router?.pathname]);

  const getSelected = useCallback(
    (path: string) => {
      const pathSplit = router?.pathname.split('/')[3];
      return (
        pathSplit === path.replace('/', '') || (path === '/' && !pathSplit)
      );
    },
    [router?.pathname],
  );

  const isEnable = useCallback(
    (path: string) => {
      const normalizePath = {
        Agenda: 'schedule',
        Calendario: 'schedule',
        Schedule: 'schedule',
        Networking: 'networking',
        Materiais: 'materials',
        Materials: 'materials',
        Materiales: 'materials',
        Feed: 'feed',
      };

      const enable = course?.sections?.[normalizePath[path]]?.enabled;
      return enable === undefined ? true : enable;
    },
    [course?.sections],
  );

  const renderMenuItems = useMemo(() => {
    const menusEnable = [];
    const menusDisable = [];

    menuItems.forEach(menu => {
      const enable = isEnable(menu.text);
      const selected = getSelected(menu.path);
      const item = (
        <ContainerMenu
          key={menu.path}
          // eslint-disable-next-line consistent-return
          onClick={() => {
            if (enable === false) {
              return 0;
            }
            router.push({
              pathname: `/curso/${course?.slug}${menu.path}`,
            });
          }}
          isEnable={enable}
        >
          <Label isSelected={selected}>{menu.text}</Label>
          <BorderBottom isSelected={selected} />
        </ContainerMenu>
      );
      if (!enable) menusDisable.push(item);
      else menusEnable.push(item);
    });

    return [...menusEnable, ...menusDisable];
  }, [course?.slug, getSelected, isEnable, menuItems, router]);

  return (
    <>
      {!hiddenMenu && (
        <Container>
          <Title>{course?.name}</Title>
          <Text>{course?.description}</Text>
          <ContainerList>{renderMenuItems}</ContainerList>
        </Container>
      )}
      {children}
    </>
  );
};

export default Menu;
