import React, { createContext, useCallback, useContext, useState } from 'react';

import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

interface ISnackBarOptions {
  type: 'success' | 'info' | 'warning' | 'error';
  duration?: number;
  message: string;
}
interface ISnackbarContextData {
  showSnackbar(options: ISnackBarOptions): void;
}

export interface State extends SnackbarOrigin {
  open: boolean;
}

const SnackbarContext = createContext<ISnackbarContextData>(
  {} as ISnackbarContextData,
);

const SnackbarProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = React.useState<State>({
    vertical: 'top',
    horizontal: 'right',
    open: false,
  });
  const { vertical, horizontal } = state;
  const [currentOptions, setCurrentOptions] = useState({} as ISnackBarOptions);

  const showSnackbar = useCallback(
    ({
      message = '',
      type = 'success',
      duration = 6000,
    }: ISnackBarOptions): void => {
      setCurrentOptions({ message, type, duration });
      setOpen(true);
    },
    [],
  );

  const handleClose = () => {
    setState({ ...state, open: false });
    setOpen(false);
  };
  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={currentOptions.duration}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={currentOptions.type}
        >
          {currentOptions.message}
        </MuiAlert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};

function useSnackbar(): ISnackbarContextData {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('Snackbar must be used within a SnackbarProvider');
  }
  return context;
}

export { useSnackbar, SnackbarProvider };
