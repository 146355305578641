import { styled, LinearProgress, withStyles } from '@material-ui/core';

export const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 12,
    width: '100%',
    borderRadius: 6,
    marginLeft: '10px',
    marginRight: '20px',
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 300 : 700],
  },
  bar: {
    borderRadius: 6,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

export const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '20px auto 0px',
  maxWidth: '480px',
});

export const Label = styled('span')({
  fontFamily: 'Barlow',
  fontSize: 15,
  color: '#000000',
  fontWeight: 500,
  marginLeft: '20px',
});
