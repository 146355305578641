import {
  ILessonItemList,
  IModulesWithLessons,
} from '@utils/interfaces/modulesWithLessons';

export interface ICourseUtils {
  getAllLessons(modules: IModulesWithLessons[]): ILessonItemList[];

  calcProgress(modules: IModulesWithLessons[]): number;

  getModuleAndLessonIndexByLessonId(
    lessonId: string,
    modules: IModulesWithLessons[],
  ): {
    lessonIndex: number;
    moduleIndex: number;
  };

  getModuleAndLessonIndexByCourseLessonId(
    courseLessonId: string,
    modules: IModulesWithLessons[],
  ): {
    lessonIndex: number;
    moduleIndex: number;
  };
}

export class CourseUtils implements ICourseUtils {
  public calcProgress(modules: IModulesWithLessons[]): number {
    const allLessons = this.getAllLessons(modules);

    const completedLessons = allLessons.filter(
      lesson => lesson.hasCompleted,
    ).length;

    const progress = Math.floor(
      (completedLessons * 100) / (allLessons.length || 1),
    );

    return progress;
  }

  public getAllLessons(modules: IModulesWithLessons[]): ILessonItemList[] {
    let allLessons: ILessonItemList[] = [];

    modules.forEach(module => {
      allLessons = [...allLessons, ...module.courseLessons];
    });

    return allLessons;
  }

  public getModuleAndLessonIndexByLessonId(
    lessonId: string,
    modules: IModulesWithLessons[],
  ): { lessonIndex: number; moduleIndex: number } {
    let lessonIndex: number;
    const moduleIndex = modules?.findIndex(module => {
      lessonIndex = module?.courseLessons?.findIndex(
        courseLesson => courseLesson.lesson.id === lessonId,
      );
      return lessonIndex !== -1;
    });

    return {
      lessonIndex,
      moduleIndex,
    };
  }

  public getModuleAndLessonIndexByCourseLessonId(
    courseLessonId: string,
    modules: IModulesWithLessons[],
  ): { lessonIndex: number; moduleIndex: number } {
    let lessonIndex: number;
    const moduleIndex = modules?.findIndex(module => {
      lessonIndex = module.courseLessons?.findIndex(
        courseLesson => courseLesson.id === courseLessonId,
      );
      return lessonIndex !== -1;
    });

    return {
      lessonIndex,
      moduleIndex,
    };
  }
}
